<template>
  <div class="travel-plan-record-add">
    <div class="getCustomer">

    </div>
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ info.customerName }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">
          授信等级：{{ data.level }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
        <van-col span="8" @click="to(data.lineDetail)">航线明细
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
      </van-row>
    </div>
    <div>
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="addPlan()">
        <van-row>
          <van-field
              readonly
              required
              label="客户："
              :value="info.customerName"
              v-model="info.customerName"
              :rules="costomerForm.customerName"
              placeholder="选择客户"
              input-align="left"
              @click="showCompanyName = true"
              label-width="6.5em"
          />
          <van-popup v-model="showCompanyName" round position="bottom">
            <van-field
                placeholder="在这里查询客户"
                v-model="selCustomerValue"
                left-icon="search"
            />
            <van-picker
                ref="picker"
                show-toolbar
                :columns="options"
                @cancel="showCompanyName = false, selCustomerValue=''"
                @confirm="onConfirmCompany"
                default-index="0">
            </van-picker>
          </van-popup>
        </van-row>
        <van-row v-if="interviewType">
          <van-field
              readonly
              required
              clickable
              label="走访类型："
              input-align="left"
              :value="info.interviewType"
              :rules="costomerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
              label-width="6.5em"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field required autosize type="textarea" label="目标及方案" :rules="costomerForm.goalPlan"
                   label-width="6.5em" placeholder="填写文本" v-model="info.goalPlan"></van-field>

        <div v-if="(info.enterpriseType == '船方' && info.enterpriseNature == '船方') || (info.enterpriseType == '船方' && info.enterpriseNature == '船东') ">
          <van-row style="background:white">
            <van-field
                required
                readonly
                clickable
                label="船舶"
            >
              <template #input>
                <van-row>
                  <van-col span="24">
                    <el-select
                        v-model="vessel"
                        multiple
                        placeholder="请选择船舶"
                        @remove-tag="handleRemoveTag"
                    >
                      <el-option
                          v-for="item in optionsVessel"
                          :key="item.val"
                          :label="item.text"
                          :value="item.text">
                      </el-option>
                    </el-select>
                  </van-col>
                </van-row>
                <span style="color: #289AE1" @click="showModal = true">新建船舶＋</span>
              </template>
            </van-field>
          </van-row>
        </div>

        <!--删除船舶-->
        <van-dialog
            v-model="giveup"
            :title=title
            show-cancel-button
            :beforeClose="giveupBtn"
            confirm-button-text='只删除记录'
            cancel-button-text='删除记录和船舶'
        >
        </van-dialog>

        <!--填写船舶信息-->
        <div>
          <Modal :visible="showModal" @close="showModal = false" ref="taskModal"
                  id="taskModal1">
<!--            <van-divider class="label" contentPosition="center">请填写船舶信息</van-divider>-->
            <van-form @submit="saveVessel()">
              <van-field required label="船舶公司：" readonly placeholder="请输入船舶公司" label-width="6.5em"
                         v-model="shipInfo.vesselCompany"></van-field>
              <van-field required label="船舶名称：" placeholder="请输入船名" label-width="6.5em" :rules="costomerForm.vesselName"
                         v-model="shipInfo.vesselName"></van-field>
              <van-field label="租船人：" placeholder="请填写租船人" label-width="6.5em"
                         v-model="shipInfo.charterer"></van-field>
              <van-field label="船东信息：" placeholder="请填写船东信息" label-width="6.5em"
                         v-model="shipInfo.customerName"></van-field>
              <van-field label="船东联系人：" placeholder="请填写船东联系人" label-width="6.5em"
                         v-model="shipInfo.contacts"></van-field>
              <van-field label="载重吨：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.dwt"></van-field>
              <van-field label="船长：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.length"></van-field>
              <van-field label="满载吃水：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.draught"></van-field>
              <van-field label="舱口数：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.hatch"></van-field>
              <van-field label="建造年限：" placeholder="请填写建造年限" label-width="6.5em"
                         v-model="shipInfo.period"></van-field>
              <van-field label="主要航线：" placeholder="请填写主要航线" label-width="6.5em"
                         v-model="shipInfo.mainRoutes"></van-field>
              <van-field  label="船舶种类：" placeholder="请填写船舶种类" label-width="6.5em"
                         v-model="shipInfo.vesselType"></van-field>
              <van-field label="主要货种：" placeholder="请填写主要货种" label-width="6.5em"
                         v-model="shipInfo.mainGoods"></van-field>
<!--              <van-field label="总舱容：" placeholder="请填写总舱容" label-width="6.5em"-->
<!--                         v-model="shipInfo.toalCapacity"></van-field>-->
              <div class="submit">
                <van-button type="default" native-type="button"
                            @click="showModal=false, vesselInfo ={}">取消
                </van-button>
                <van-button type="info" native-type="submit">确定</van-button>
              </div>
            </van-form>
          </Modal>

        </div>

        <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
    </div>
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import Modal from './../../components/Modal/MyCustomerModal';
import {saveBulkShipinfotwo, getBulkCrmShipinfo, deleteBulkShipinfotwo} from "../../api/shipInfo";
import {Toast} from "vant";

export default {
  name: "BulkCrmsTravelPlanRecordAdd",
  components: {CrmTabBar, Modal},

  data() {
    return {
      title: '请选择删除情况',
      giveup: false,
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      vessel: [],
      showModal: false,
      showCompanyName: false,
      isHidden: false,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      lostDate: this.formatter(),
      currentDate: new Date(),
      data: {
        title: '',
        level: '',
        weight: '',
        benefit: '',
        lineDetail: '',
        planDetail: 'planDetail'
      },
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      info: {
        customerName: '',
        crmCustomerid: '',
        travelDate: this.formatter(),
        target: '',
        situation: '',
        plan: '',
        remarks: '',
        interviewType: '',
        goalPlan: '',
        enterpriseType: '',
        enterpriseNature: ''
      },
      optionsVessel: [],
      options: [],
      columns: [],
      selCustomerValue: '',
      loading: false,
      showInterviewTypePicker: false,
      costomerForm: {
        customerName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '请填写目标', trigger: 'blur'},
        ],
        situation: [
          {required: true, message: '请填写现状', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '请填写计划方案', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        selectName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        vesselName: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      deleteOption:''
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    //监听公司名头
    'selCustomerValue': function () {
      if (this.selCustomerValue == '') {
        this.options = []
        for (let i = 0; i < this.columns.length; i++) {
          var array = {}
          array.text = this.columns[i].customerName
          array.value = this.columns[i].crmCustomerid
          array.enterpriseType = this.columns[i].enterpriseType
          array.enterpriseNature = this.columns[i].enterpriseNature
          this.options.push(array)
        }
      } else {
        this.options = this.options.filter(item => item.text.includes(this.selCustomerValue))
      }
    },
    'vessel': function (newValue,oldValue) {

    },
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getAllBulkCrmCustomertwoList.json',
          {
            customerName: this.selCustomerValue,
            userNo: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          this.columns = res.data;
          for (let i = 0; i < res.data.length; i++) {
            var array = {}
            array.text = res.data[i].customerName
            array.value = res.data[i].crmCustomerid
            array.enterpriseType = res.data[i].enterpriseType
            array.enterpriseNature = res.data[i].enterpriseNature
            this.options.push(array)
          }
          console.log(this.options)

        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    handleRemoveTag(value){
      const option = this.optionsVessel.find(item => item.text === value)
      if(option.remarks === "新增"){
        this.deleteOption = option.vesselId
        this.giveup = true
      }
    },

    giveupBtn(action, done) {
      if (action === 'confirm') {
        done()
      } else if (action === 'cancel') {
        deleteBulkShipinfotwo({
          vesselId: this.deleteOption
        }).then(res => {
          done()
          let index = this.optionsVessel.indexOf(this.deleteOption);
          //如果找到该元素，使用splice方法删除元素
          this.optionsVessel.splice(index, 1);
        }).catch(err => {
          console.log(err)
        });
        done()
      }
    },
    to(to) {
      this.$router.push(to)
    },
    addPlan(formName) {
      if((this.info.enterpriseType == '船方' && this.info.enterpriseNature == '船方') || (this.info.enterpriseType == '船方' && this.info.enterpriseNature == '船东')){
        if(this.vessel.length==0){
          this.$toast({
            message: '请完善船舶信息！',
            position: 'top'
          });
        }
        else{
          let date = new Date();
          console.log(this.info)
          this.$ajax.post('/api/ajax/planVisit/saveBulkCrmPlanvisit.json',
              {
                customerName: this.info.customerName,
                crmCustomerid: this.info.crmCustomerid,
                titleName: this.$route.params.name,
                titleId: this.$route.params.id,
                plandateStart: this.$route.params.start,
                plandateEnd: this.$route.params.end,
                travelType: '001',
                interviewType: this.info.interviewType,
                goalPlan: this.info.goalPlan,
                createdPeople: this.$store.getters.userId,
                vessel: this.vessel
              }).then(res => {
            if (res.status == 200) {
              this.$message.success('保存成功！');
              setTimeout(() => {
                if (this.$route.params.type == 'add') {
                  this.$router.replace({path: '/BulkCrmsTravelPlanAdd/' + this.$route.params.id})
                } else {
                  this.$router.replace({path: '/BulkCrmsTravelPlanDetail/' + this.$route.params.id})
                }
              }, 200);
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('保存失败！');
          })
        }
      }else{
        let date = new Date();
        console.log(this.info)
        this.$ajax.post('/api/ajax/planVisit/saveBulkCrmPlanvisit.json',
            {
              customerName: this.info.customerName,
              crmCustomerid: this.info.crmCustomerid,
              titleName: this.$route.params.name,
              titleId: this.$route.params.id,
              plandateStart: this.$route.params.start,
              plandateEnd: this.$route.params.end,
              travelType: '001',
              interviewType: this.info.interviewType,
              goalPlan: this.info.goalPlan,
              createdPeople: this.$store.getters.userId,
              vessel: this.vessel
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('保存成功！');
            setTimeout(() => {
              if (this.$route.params.type == 'add') {
                this.$router.replace({path: '/BulkCrmsTravelPlanAdd/' + this.$route.params.id})
              } else {
                this.$router.replace({path: '/BulkCrmsTravelPlanDetail/' + this.$route.params.id})
              }
            }, 200);
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('保存失败！');
        })
      }
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;
      return nowtime;
    },
    onConfirm(time) {
      this.info.travelDate = this.formatter(time);
      this.showPicker = false;
    },
    handleSelectName(item) {
      console.log(item)
      this.info.customerName = item.customerName
      this.info.crmCustomerid = item.crmCustomerid
    },
    onConfirmCompany(picker, value) {
      console.log(picker, value);
      this.info.customerName = picker.text;
      this.info.enterpriseType = picker.enterpriseType;
      this.info.enterpriseNature = picker.enterpriseNature;
      this.shipInfo.vesselCompany = picker.text;
      console.log(this.info)
      this.showCompanyName = false;
      this.selCustomerValue = ''
      this.vessel = []
      this.getCompanyData(picker)
      if(picker.enterpriseType == '船方'){
        getBulkCrmShipinfo({
          shipInformationid: this.info.crmCustomerid
        }).then(res => {
          this.optionsVessel = res
          console.log(1)
          console.log(this.optionsVessel)
        }).catch(err => {
          console.log(err)
        });
      }
    },
    getCompanyData(picker) {
      this.info.crmCustomerid = picker.value
      this.data.lineDetail = '/LineDetail/' + picker.text
      //获取客户信息
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getHlxy.json',
          {
            customerName: picker.text
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.data.weight = res.data[0].hfDTO.weight + "万吨"
            this.data.benefit = res.data[0].hfDTO.profit + "万元"
            this.data.level = res.data[0].customerLevels
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('获取客户货量效益失败！');
      })
    },
    onClickType() {
      this.showInterviewTypePicker = true
      this.info.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      this.info.interviewType = picker;
      this.showInterviewTypePicker = false;
    },
    saveVessel(){
      saveBulkShipinfotwo({
        shipInformationid: this.info.crmCustomerid,
        vesselName: this.shipInfo.vesselName,
        charterer: this.shipInfo.charterer,
        vesselCompany: this.shipInfo.vesselCompany,
        cooperationTimes: this.shipInfo.cooperationTimes,
        dwt: this.shipInfo.dwt,
        length: this.shipInfo.length,
        draught: this.shipInfo.draught,
        hatch: this.shipInfo.hatch,
        period: this.shipInfo.period,
        mainRoutes: this.shipInfo.mainRoutes,
        vesselType: this.shipInfo.vesselType,
        mainGoods: this.shipInfo.mainGoods,
        toalCapacity: this.shipInfo.toalCapacity,
        capacity: this.shipInfo.capacity,
        createdPeople: this.$store.getters.userId,
      }).then(res => {
        this.vessel.push(this.shipInfo.vesselName)
        let array={}
        array.val=this.info.crmCustomerid
        array.text = this.shipInfo.vesselName
        array.remarks = '新增'
        array.vesselId = res.vesselId
        this.optionsVessel.push(array)
        console.log("船舶信息",this.optionsVessel)
        this.clearShip()
        Toast.success('添加船舶成功！');
      }).catch(err => {
        console.log(err)
      });
    },
    clearShip(){
      this.showModal = false
      this.shipInfo = {
        vesselCompany: this.shipInfo.vesselCompany,
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      }
    },
    success(res) {
      this.show = res
    },
  }
}
</script>

<style scoped>
.title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.travel-plan-record-add .card {
  background: #318EF5;
}

.info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.selectInfo .el-select {
  display: block;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.submit .van-button {
  width: 150px;
}

</style>

<style>
.travel-plan-record-add .el-input__inner {
  border: none;
  padding: 0 3px;
}

/* 定义过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
}
</style>